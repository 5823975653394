import * as React from 'react'
import { css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { margin } from 'polished'
import IndexLayout from '../layouts'
import Page from '../components/Page'
import { Row } from '../components/Row'
import Col from '../components/Col'
import Container from '../components/Container'
import { breakpoints, colors } from '../styles/variables'
import StickyNav from '../components/StickyNav'
import Footer from '../components/Footer'
import { getEmSize } from '../styles/mixins'

const MessageStyle = css`
  width: 100%;
  resize: none;
`
const float2 = keyframes`
0% {
  transform: scale(2) translateX(14%) translateY(0rem);
              //Media Query here
  @media screen and (max-width: ${getEmSize(breakpoints.md)}em) {
    transform: scale(2) translateX(2%) translateY(0rem);
    width: 100%;
    height: 50%;
  }
}
50% {
  transform: scale(2) translateX(14%) translateY(0.25rem);
              //Media Query here
  @media screen and (max-width: ${getEmSize(breakpoints.md)}em) {
    transform: scale(2) translateX(2%) translateY(0rem);
    width: 100%;
    height: 50%;
  }
}
100% {
  transform: scale(2) translateX(14%) translateY(0rem);
              //Media Query here
  @media screen and (max-width: ${getEmSize(breakpoints.md)}em) {
    transform: scale(2) translateX(2%) translateY(0rem);
    width: 100%;
    height: 50%;
  }
}
`

const Contact = () => (
  <IndexLayout>
    <Page>
      <StickyNav />
      <Container>
        <h1 css={{ color: colors.red }}>Interested in working together?</h1>
        <h5>Send me a message and lets start working!</h5>

        <Row css={{ marginTop: 0, paddingTop: 0 }}>
          {/* <Col span={3} css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
          {/* <p>Use the form to get in contact straight away or email ross@rossmacd.com</p> */}
          {/* <svg
              viewBox="0 0 200 200"
              xmlns="http://www.w3.org/2000/svg"
              css={css`
                position: absolute;
                transform: scale(2) translateX(14%);
                top: 0;
                left: 0;
                z-index: 1;
                opacity: 0.45;
                //Media Query here
                @media screen and (max-width: ${getEmSize(breakpoints.md)}em) {
                  transform: scale(2) translateX(-2%);
                  width: 100%;
                  height: 50%;
                }
                height: 100%;
                width: auto;
                animation: ${float2} 6s ease infinite;
              `}
            >
              <path
                fill="#ff005a"
                d="M47.6,-61.3C62.8,-54.5,77,-42,83.9,-26C90.9,-10,90.5,9.6,81.3,22.9C72.1,36.1,54.1,42.9,39.1,51.3C24.1,59.7,12,69.7,-2.1,72.5C-16.1,75.4,-32.3,71,-43.9,61.5C-55.5,52,-62.6,37.3,-67.4,22C-72.2,6.7,-74.7,-9.2,-72.6,-26C-70.4,-42.7,-63.5,-60.3,-50.6,-67.9C-37.8,-75.5,-18.9,-73,-1.3,-71.2C16.2,-69.3,32.4,-68.1,47.6,-61.3Z"
                transform="translate(100 100)"
              />
            </svg> */}
          {/* </Col> */}
          <Col span={3}>
            <h2>Contact</h2>
            <p>Name</p>
            <input type="text" />
            <p>Email</p>
            <input type="email" />
            <p>Message</p>
            <textarea rows={8} css={MessageStyle} />
            <input type="submit" />
          </Col>
        </Row>
      </Container>
      <Footer />
    </Page>
  </IndexLayout>
)

export default Contact
